<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{ $t('signup.title') }}</p>
        </div>
        <div class="form-horizontal ng-untouched ng-pristine ng-valid">
          <div class="bTable">
            <div class="w50L">
              <p class="th">
                <span id="t_id">{{ $t('signup.id') }}</span>
                <span style="color: red;">*</span>
              </p>
              <p class="td input mb-2">
                <input type="text" name="id" id="id" value="" engnumberonly="true"
                       autocomplete="new-password" ng-reflect-name="id"
                       class="ng-untouched ng-pristine ng-valid" v-model="id">
              </p>
            </div>
            <div class="w50R">
              <p class="th">
                <span id="t_password">{{ $t('signup.password') }}</span>
                <span style="color: red;">*</span>
              </p>
              <p class="td input mb-2">
                <input type="password" name="passwd" id="passwd" value="" engnumberonly="true"
                       autocomplete="new-password" ng-reflect-name="passwd"
                       class="ng-untouched ng-pristine ng-valid" v-model="password">
              </p>
            </div>
            <div class="clearfix"></div>
            <div class="w50L">
              <p class="th">
                <span id="t_name">{{ $t('signup.name') }}</span><span style="color: red;">*</span>
              </p>
              <p class="td input">
                <input type="text" name="name" id="name" value="" autocomplete="new-password"
                       class="motion ng-untouched ng-pristine ng-valid" ng-reflect-name="name"
                       v-model="name">
              </p>
              <p id="t_name_ex" class="cap"></p>

            </div>
            <div class="w50R">
              <p class="th">
                <span _ngcontent-ghy-c59="" id="t_email">{{ $t('signup.email') }}</span><span
                  style="color: red;">*</span>
              </p>
              <p class="td input">
                <input type="text" name="email" id="email" value="" v-model="email"
                       engnumberonly="true" class="motion ng-untouched ng-pristine ng-valid"
                       ng-reflect-name="email"></p>
              <div class="clearfix"></div>
              <p id="chkemail_result" class="cap"></p>

            </div>
            <div class="clearfix"></div>
            <div class="w50L">
              <p class="th">
                <span _ngcontent-ghy-c59="" id="t_mobile">{{ $t('signup.mobile') }}</span><span
                  style="color: red;">*</span>
              </p>
              <p class="td input">
                <input type="text" name="mobile" id="mobile" value="" v-model="mobile"
                       engnumberonly="true" class="motion ng-untouched ng-pristine ng-valid"
                       ng-reflect-name="mobile"></p>
              <div class="clearfix"></div>
              <p id="chkmobile_result" class="cap"></p>
            </div>
            <div class="w50R">
              <p class="th"><span id="t_country">{{ $t('signup.nation') }}</span></p>
              <p class="td input position-relative">
                <select name="country" ng-reflect-name="country" ng-reflect-model="South Korea"
                        class="ng-untouched ng-pristine ng-valid text-white" v-model="country">
                  <option :value=item.code class="ng-star-inserted"
                          v-for="(item,index) in country_list" :key="'l_'+index">{{ item.text }}
                  </option>
                </select>
              </p>
            </div>
            <div class="clearfix"></div>
            <div class="bWrap">
              <button class="bOk card_bg5" @click="signUp()">{{ $t('common.submit') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      country: 'KR',
      id: '',
      name: '',
      email: '',
      password: '',
      password_confirm: '',
      mobile: '',
      reffer: '',
      loading: false,
      country_list: [
        {code: "US", text: "United States"},
        {code: "KR", text: "South Korea"}
      ]
    }
  },
  mounted() {
    this.CheckInfo();
  },
  methods: {
    CheckInfo() {
      this.$axios.post('/member/sign/CheckInfo', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.reffer = body.reffer;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    signUp() {
      const id = this.id.trim();
      const name = this.name.trim();
      const reffer = this.reffer.trim();
      const email = this.email.trim();
      const country = this.country.trim();
      const password = this.password.trim();
      const mobile = this.mobile.trim();

      if (id === '') {
        this.$alert(this.$i18n.t('signup.alertId'));
        return false;
      }

      if (name === '') {
        this.$alert(this.$i18n.t('signup.alertName'));
        return false;
      }

      if (email === '') {
        this.$alert(this.$i18n.t('signup.alertEmail'));
        return false;
      }

      if (password === '') {
        this.$alert(this.$i18n.t('signup.alertPassword'));
        return false;
      }

      if (reffer === '') {
        this.$alert(this.$i18n.t('signup.alertRefer'));
        return false;
      }

      if (mobile === '') {
        this.$alert(this.$i18n.t('signup.alertMobile'));
        return false;
      }

      const body = {id, email, name, mobile, password, reffer, country};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$axios.post('/member/sign', {req}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$alert(this.$i18n.t('signup.signupComplete')).then(
                    () => {
                      this.$router.go();
                    }
                )
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              } else if (res.data.code >= 130) {
                this.loading = false;
                this.$alert(res.data.msg)
              }
            }
          }
      )
    },
  }
}
</script>

<style>

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
</style>
